export enum BlockTypeHandle {
  OFFER_TILES = 'offerTiles',
  AGREEMENT_TILES = 'agreementTiles',
  AUTOBLOCK = 'autoblock',
  CTA_EVENT = 'ctaEvent',
  CTA_OFFER = 'ctaOffer',
  CTA_OFFICE = 'ctaOffice',
  CTA_SERVICE = 'ctaService',
  CTA_CONTACT = 'ctaContact',
  CTA_CONTACT_MINILU = 'ctaContactMinilu',
  CTA = 'cta',
  PERSONIO_JOBS = 'personioJobs',
  TOP_SPEAKERS = 'topSpeakers',
  CIRCLE_TEXT = 'circleText',
  CONTACT_REQUEST = 'contactRequest',
  CONTACT_REQUEST_MINILU = 'contactRequestMinilu',
  CONTENT_PROCESS = 'contentProcess',
  CONTENT_TEASER = 'teaser',
  FLOWCHART = 'flowchart',
  TEXT_IMAGE = 'textImage',
  HIGHLIGHT = 'highlight',
  STYLED_SECTION = 'styledSection',
  ACCORDION_FAQS = 'accordionFAQs',
  AREA_INTRO_TOP_BANNER = 'areaIntroTopBanner',
  BRANDS = 'brands',
  TEAM = 'team',
  CONTENT_IMAGE = 'image',
  SEPERATOR = 'seperator',
  HEADLINE = 'headline',
  MAILCHIMP_PAYWALL_FORM = 'mailchimpPaywallForm',
  GRID_TABLE = 'gridTable',
  PERSON_RATING = 'personRating',
  ROOMPLANNING_REFERENCES = 'roomplanningReferences',
  TEXT_IMAGE_TILES = 'textImageTiles',
  CONTENT_HEADER = 'contentHeader',
  COMPARISON_TABLE = 'comparisonTable',
  CONTENT_ENUMERATION = 'enumeration',
  EVENT_DETAILED = 'eventDetailed',
  EVENT_ENUMERATION = 'eventEnumeration',
  FAQ_ADVANCED = 'faqAdvanced',
  FILE_DOWNLOADS = 'fileDownloads',
  NEWSLETTER_BANNER = 'newsletterBanner',
  OFFICE_TABLE = 'officeTable',
  VIDEO = 'video',
  LIST = 'list',
  FORM = 'form',
  FORM_DISTURBANCE_REQUEST = 'formDisturbanceRequest',
  FORM_REPAIRSHOP_REQUEST = 'formRepairshopRequest',
  EVENT_INTRODUCTION = 'eventIntroduction',
  IMAGE_GALLERY_EXTENDED = 'imageGalleryExtended',
  IMAGE_GALLERY = 'imageGallery',
  INTRODUCTION = 'introduction',
  PERSON = 'personBlock',
  POST_INTRODUCTION = 'postIntroduction',
  SERVICE_INTRODUCTION = 'serviceIntroduction',
  TEXT = 'text',
  TOP_BANNER = 'topBanner',
  WYSIWYG_CONTENT = 'wysiwygContent',
  EVENT_CME_POINTS = 'eventCmePoints',
  GLOSSARY = 'glossary',
  INFOBANNER = 'infobanner',
  FAQ = 'faq',
  RETURNS_CONTACT = 'returnsContact',
  QUOTE = 'quote',
  POST_LIST = 'postList',
  EVENT_LIST = 'eventList',
  OFFICE_LIST = 'officeList',
  OFFER_LIST = 'offerList',
  PRINT_HEADER = 'printHeader',
  PRINT_FOOTER = 'printFooter',
  PRODUUCT_RECOMMENDATIONS = 'productRecommendations',
  CAMPAIGN_LIST = 'campaignList',
  MANUFACTURER_OVERVIEW = 'manufacturerOverview',
  STARTPAGE_TEMPLATE = 'startpageTemplate',
  BANNER_POSITION = 'bannerPosition',
  MANUFACTURER_PRODUCT_LIST = 'manufacturerProductList',
  PRODUCT_TAG_PRODUCT_LIST = 'productTagProductList',
  PRODUCT_NUMBER_PRODUCT_LIST = 'productNumberProductList',
  CUPO_ONLY_CONTAINER = 'cupoOnlyContainer',
}

export interface BaseBlockType<T = BlockTypeHandle> {
  typeHandle: T;
  id: string;
}
